(function (t) {
// en
t.add("Add", "Add", "javascript", "en");
t.add("Add a new event date", "Add a new event date", "javascript", "en");
t.add("Add a new ticket", "Add a new ticket", "javascript", "en");
t.add("An error has occured", "An error has occured", "javascript", "en");
t.add("April", "April", "javascript", "en");
t.add("August", "August", "javascript", "en");
t.add("Cancel", "Cancel", "javascript", "en");
t.add("Close", "Close", "javascript", "en");
t.add("Confirm", "Confirm", "javascript", "en");
t.add("Confirmation required", "Confirmation required", "javascript", "en");
t.add("Day", "Day", "javascript", "en");
t.add("December", "December", "javascript", "en");
t.add("Duplicate this event date", "Duplicate this event date", "javascript", "en");
t.add("Duplicate this ticket", "Duplicate this ticket", "javascript", "en");
t.add("Email address", "Email address", "javascript", "en");
t.add("February", "February", "javascript", "en");
t.add("Insert at least 3 characters", "Insert at least 3 characters", "javascript", "en");
t.add("January", "January", "javascript", "en");
t.add("July", "July", "javascript", "en");
t.add("June", "June", "javascript", "en");
t.add("Load more", "Load more", "javascript", "en");
t.add("Loading...", "Loading...", "javascript", "en");
t.add("Mail server test email", "Mail server test email", "javascript", "en");
t.add("Make sure to enter a valid email address", "Make sure to enter a valid email address", "javascript", "en");
t.add("There are multiple hours at this date", "There are multiple hours at this date", "javascript", "en");
t.add("March", "March", "javascript", "en");
t.add("May", "May", "javascript", "en");
t.add("Message", "Message", "javascript", "en");
t.add("Month", "Month", "javascript", "en");
t.add("Move down", "Move down", "javascript", "en");
t.add("Move up", "Move up", "javascript", "en");
t.add("No results found", "No results found", "javascript", "en");
t.add("Note", "Note", "javascript", "en");
t.add("November", "November", "javascript", "en");
t.add("October", "October", "javascript", "en");
t.add("Please select the tickets quantity for this order", "Please select the tickets quantity for this order", "javascript", "en");
t.add("Please select the tickets quantity you want to buy", "Please select the tickets quantity you want to buy", "javascript", "en");
t.add("Read more", "Read more", "javascript", "en");
t.add("Remove", "Remove", "javascript", "en");
t.add("Remove this event date", "Remove this event date", "javascript", "en");
t.add("Remove this ticket", "Remove this ticket", "javascript", "en");
t.add("Return to cart", "Return to cart", "javascript", "en");
t.add("Searching ...", "Searching ...", "javascript", "en");
t.add("Select an option", "Select an option", "javascript", "en");
t.add("Send", "Send", "javascript", "en");
t.add("September", "September", "javascript", "en");
t.add("Stick", "Stick", "javascript", "en");
t.add("The email address is not valid", "The email address is not valid", "javascript", "en");
t.add("The tickets have been released", "The tickets have been released", "javascript", "en");
t.add("This feature is disabled in demo mode", "This feature is disabled in demo mode", "javascript", "en");
t.add("Time's up", "Time's up", "javascript", "en");
t.add("Today", "Today", "javascript", "en");
t.add("Unstick", "Unstick", "javascript", "en");
t.add("Week", "Week", "javascript", "en");
t.add("days", "days", "javascript", "en");
t.add("days.Fri", "Fri", "javascript", "en");
t.add("days.Mon", "Mon", "javascript", "en");
t.add("days.Sat", "Sat", "javascript", "en");
t.add("days.Sun", "Sun", "javascript", "en");
t.add("days.Thu", "Thu", "javascript", "en");
t.add("days.Tue", "Tue", "javascript", "en");
t.add("days.Wed", "Wed", "javascript", "en");
t.add("hr", "hr", "javascript", "en");
t.add("javascript string translation", "javascript string translation", "javascript", "en");
t.add("min", "min", "javascript", "en");
t.add("sec", "sec", "javascript", "en");
t.add("weeks", "weeks", "javascript", "en");
t.add("Stage", "Stage", "javascript", "en");
t.add("Change stage name", "Change stage name", "javascript", "en");
t.add("Change stage background color", "Change stage background color", "javascript", "en");
t.add("Manage section seats", "Manage section seats", "javascript", "en");
t.add("Change section name", "Change section name", "javascript", "en");
t.add("Change section background color", "Change section background color", "javascript", "en");
t.add("Duplicate section", "Duplicate section", "javascript", "en");
t.add("Delete section", "Delete section", "javascript", "en");
t.add("Add a new row", "Add a new row", "javascript", "en");
t.add("Row", "Row", "javascript", "en");
t.add("Row label", "Row label", "javascript", "en");
t.add("Row prefix", "Row prefix", "javascript", "en");
t.add("Seats numbers direction", "Seats numbers direction", "javascript", "en");
t.add("Row seats color", "Row seats color", "javascript", "en");
t.add("Row seats start number", "Row seats start number", "javascript", "en");
t.add("Row seats end number", "Row seats end number", "javascript", "en");
t.add("Disabled seats", "Disabled seats", "javascript", "en");
t.add("Hidden seats", "Hidden seats", "javascript", "en");
t.add("Left to right", "Left to right", "javascript", "en");
t.add("Right to left", "Right to left", "javascript", "en");
t.add("Section name has to be unique", "Section name has to be unique", "javascript", "en");
t.add("Duplicated", "Duplicated", "javascript", "en");
t.add("Delete section row", "Delete section row", "javascript", "en");
t.add("Row prefix can not be empty", "Row prefix can not be empty", "javascript", "en");
t.add("Row prefix must be unique per section", "Row prefix must be unique per section", "javascript", "en");
t.add("Row seats color can not be empty", "Row seats color can not be empty", "javascript", "en");
t.add("Row seats start number can not be empty", "Row seats start number can not be empty", "javascript", "en");
t.add("Row seats end number can not be empty", "Row seats end number can not be empty", "javascript", "en");
t.add("Row seats end number can not be lesser than Row seats start number", "Row seats end number can not be lesser than Row seats start number", "javascript", "en");
t.add("No matches found", "No matches found", "javascript", "en");
t.add("You have reached the limit for this ticket type", "You have reached the limit for this ticket type", "javascript", "en");
t.add("Please select the tickets quantity for this order", "Please select the tickets quantity for this order", "javascript", "en");
})(Translator);