(function (t) {
// fr
t.add("Add", "Ajouter", "javascript", "fr");
t.add("Add a new event date", "Ajouter une nouvelle date d'\u00e9v\u00e9nement", "javascript", "fr");
t.add("Add a new ticket", "Ajouter un nouveau billet", "javascript", "fr");
t.add("An error has occured", "Une erreur est survenue", "javascript", "fr");
t.add("April", "Avril", "javascript", "fr");
t.add("August", "Ao\u00fbt", "javascript", "fr");
t.add("Cancel", "Annuler", "javascript", "fr");
t.add("Close", "Fermer", "javascript", "fr");
t.add("Confirm", "Confirmer", "javascript", "fr");
t.add("Confirmation required", "Confirmation requise", "javascript", "fr");
t.add("Day", "Jour", "javascript", "fr");
t.add("December", "D\u00e9cembre", "javascript", "fr");
t.add("Duplicate this event date", "Dupliquer cette date d'\u00e9v\u00e9nement", "javascript", "fr");
t.add("Duplicate this ticket", "Dupliquer ce billet", "javascript", "fr");
t.add("Email address", "Adresse e-mail", "javascript", "fr");
t.add("February", "F\u00e9vrier", "javascript", "fr");
t.add("Insert at least 3 characters", "Ins\u00e9rez au moins 3 caract\u00e8res", "javascript", "fr");
t.add("January", "Janvier", "javascript", "fr");
t.add("July", "Juillet", "javascript", "fr");
t.add("June", "Juin", "javascript", "fr");
t.add("Load more", "Charger plus", "javascript", "fr");
t.add("Loading...", "Chargement...", "javascript", "fr");
t.add("Mail server test email", "Email de test de la configuration du serveur d'envoi des emails", "javascript", "fr");
t.add("Make sure to enter a valid email address", "Assurez-vous de saisir une adresse e-mail valide", "javascript", "fr");
t.add("There are multiple hours at this date", "Choix de l'heure et du lieu de l'événement", "javascript", "fr");
t.add("March", "Mars", "javascript", "fr");
t.add("May", "Mai", "javascript", "fr");
t.add("Message", "Message", "javascript", "fr");
t.add("Month", "Mois", "javascript", "fr");
t.add("Move down", "D\u00e9placer vers le bas", "javascript", "fr");
t.add("Move up", "D\u00e9placer vers le haut", "javascript", "fr");
t.add("No results found", "Aucun r\u00e9sultat trouv\u00e9", "javascript", "fr");
t.add("Note", "Note", "javascript", "fr");
t.add("November", "Novembre", "javascript", "fr");
t.add("October", "Octobre", "javascript", "fr");
t.add("Please select the tickets quantity for this order", "Veuillez s\u00e9lectionner la quantit\u00e9 de billets pour cette commande", "javascript", "fr");
t.add("Please select the tickets quantity you want to buy", "Veuillez s\u00e9lectionner la quantit\u00e9 de billets que vous souhaitez acheter", "javascript", "fr");
t.add("Read more", "Lire la suite", "javascript", "fr");
t.add("Remove", "Supprimer", "javascript", "fr");
t.add("Remove this event date", "Supprimer cette date d'\u00e9v\u00e9nement", "javascript", "fr");
t.add("Remove this ticket", "Supprimer ce billet", "javascript", "fr");
t.add("Return to cart", "Retour au panier", "javascript", "fr");
t.add("Searching ...", "Recherche ...", "javascript", "fr");
t.add("Select an option", "S\u00e9lectionnez une option", "javascript", "fr");
t.add("Send", "Envoyer", "javascript", "fr");
t.add("September", "Septembre", "javascript", "fr");
t.add("Stick", "Pers\u00e9v\u00e9rer", "javascript", "fr");
t.add("The email address is not valid", "L'adresse e-mail n'est pas valide", "javascript", "fr");
t.add("The tickets have been released", "Les billets ont \u00e9t\u00e9 lib\u00e9r\u00e9s", "javascript", "fr");
t.add("This feature is disabled in demo mode", "Cette fonctionnalit\u00e9 est d\u00e9sactiv\u00e9e en mode d\u00e9mo", "javascript", "fr");
t.add("Time's up", "Le temps est \u00e9coul\u00e9", "javascript", "fr");
t.add("Today", "Aujourd'hui", "javascript", "fr");
t.add("Unstick", "D\u00e9coller", "javascript", "fr");
t.add("Week", "Semaine", "javascript", "fr");
t.add("days", "jours", "javascript", "fr");
t.add("days.Fri", "Ven", "javascript", "fr");
t.add("days.Mon", "Lun", "javascript", "fr");
t.add("days.Sat", "Sam", "javascript", "fr");
t.add("days.Sun", "Dim", "javascript", "fr");
t.add("days.Thu", "Jeu", "javascript", "fr");
t.add("days.Tue", "Mar", "javascript", "fr");
t.add("days.Wed", "Mer", "javascript", "fr");
t.add("hr", "heures", "javascript", "fr");
t.add("javascript string translation", "javascript string translation", "javascript", "fr");
t.add("min", "min", "javascript", "fr");
t.add("sec", "sec", "javascript", "fr");
t.add("weeks", "sem", "javascript", "fr");
t.add("Stage", "Scène", "javascript", "fr");
t.add("Change stage name", "Changer le nom de la Scène", "javascript", "fr");
t.add("Change stage background color", "Changer la couleur d'arrière-plan de la scène", "javascript", "fr");
t.add("Manage section seats", "Gérer les sièges de la section", "javascript", "fr");
t.add("Change section name", "Changer le nom de la section", "javascript", "fr");
t.add("Change section background color", "Changer la couleur d’arrière-plan de la section", "javascript", "fr");
t.add("Duplicate section", "Dupliquer la section", "javascript", "fr");
t.add("Delete section", "Supprimer la section", "javascript", "fr");
t.add("Add a new row", "Ajouter une nouvelle rangée", "javascript", "fr");
t.add("Row", "Rangée", "javascript", "fr");
t.add("Row label", "Nom de la rangée", "javascript", "fr");
t.add("Row prefix", "Prefix de la rangée", "javascript", "fr");
t.add("Seats numbers direction", "Sens des n° des sièges", "javascript", "fr");
t.add("Row seats color", "Couleur des sièges", "javascript", "fr");
t.add("Row seats start number", "Numéro de début", "javascript", "fr");
t.add("Row seats end number", "Numéro de fin", "javascript", "fr");
t.add("Disabled seats", "Sièges désactivés", "javascript", "fr");
t.add("Hidden seats", "Sièges masqués", "javascript", "fr");
t.add("Left to right", "De gauche à droite", "javascript", "fr");
t.add("Right to left", "De droite à gauche", "javascript", "fr");
t.add("Section name has to be unique", "Le nom de la section doit être unique", "javascript", "fr");
t.add("Duplicated", "Copie", "javascript", "fr");
t.add("Delete section row", "Supprimer la rangée de la section", "javascript", "fr");
t.add("Row prefix can not be empty", "Le préfixe de la rangée ne peut pas être vide", "javascript", "fr");
t.add("Row prefix must be unique per section", "Le préfixe de la rangée doit être unique pour chaque section", "javascript", "fr");
t.add("Row seats color can not be empty", "La couleur des sièges de la rangée ne peut pas être vide", "javascript", "fr");
t.add("Row seats start number can not be empty", "Le numéro du premier siège ne peut pas être vide", "javascript", "fr");
t.add("Row seats end number can not be empty", "Le numéro du dernier siège ne peut pas être vide", "javascript", "fr");
t.add("Row seats end number can not be lesser than Row seats start number", "Le numéro du dernier siège ne peut pas être inférieur au numéro du premier siège.", "javascript", "fr");
t.add("No matches found", "Aucune correspondance", "javascript", "fr");
t.add("You have reached the limit for this ticket type", "Vous avez atteint la limite pour ce type de billet", "javascript", "fr");
t.add("Please select the tickets quantity for this order", "Veuillez sélectionner la quantité de billets pour cette commande", "javascript", "fr");
})(Translator);