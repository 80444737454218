(function (t) {
// it
t.add("Add", "Add", "javascript", "it");
t.add("Add a new event date", "Add a new event date", "javascript", "it");
t.add("Add a new ticket", "Add a new ticket", "javascript", "it");
t.add("An error has occured", "An error has occured", "javascript", "it");
t.add("April", "April", "javascript", "it");
t.add("August", "August", "javascript", "it");
t.add("Cancel", "Cancel", "javascript", "it");
t.add("Close", "Close", "javascript", "it");
t.add("Confirm", "Confirm", "javascript", "it");
t.add("Confirmation required", "Confirmation required", "javascript", "it");
t.add("Day", "Day", "javascript", "it");
t.add("December", "December", "javascript", "it");
t.add("Duplicate this event date", "Duplicate this event date", "javascript", "it");
t.add("Duplicate this ticket", "Duplicate this ticket", "javascript", "it");
t.add("Email address", "Email address", "javascript", "it");
t.add("February", "February", "javascript", "it");
t.add("Insert at least 3 characters", "Insert at least 3 characters", "javascript", "it");
t.add("January", "January", "javascript", "it");
t.add("July", "July", "javascript", "it");
t.add("June", "June", "javascript", "it");
t.add("Load more", "Load more", "javascript", "it");
t.add("Loading...", "Loading...", "javascript", "it");
t.add("Mail server test email", "Mail server test email", "javascript", "it");
t.add("Make sure to enter a valid email address", "Make sure to enter a valid email address", "javascript", "it");
t.add("March", "March", "javascript", "it");
t.add("May", "May", "javascript", "it");
t.add("Message", "Message", "javascript", "it");
t.add("Month", "Month", "javascript", "it");
t.add("Move down", "Move down", "javascript", "it");
t.add("Move up", "Move up", "javascript", "it");
t.add("No results found", "No results found", "javascript", "it");
t.add("Note", "Note", "javascript", "it");
t.add("November", "November", "javascript", "it");
t.add("October", "October", "javascript", "it");
t.add("Please select the tickets quantity for this order", "Please select the tickets quantity for this order", "javascript", "it");
t.add("Please select the tickets quantity you want to buy", "Please select the tickets quantity you want to buy", "javascript", "it");
t.add("Read more", "Read more", "javascript", "it");
t.add("Remove", "Remove", "javascript", "it");
t.add("Remove this event date", "Remove this event date", "javascript", "it");
t.add("Remove this ticket", "Remove this ticket", "javascript", "it");
t.add("Return to cart", "Return to cart", "javascript", "it");
t.add("Searching ...", "Searching ...", "javascript", "it");
t.add("Select an option", "Select an option", "javascript", "it");
t.add("Send", "Send", "javascript", "it");
t.add("September", "September", "javascript", "it");
t.add("Stick", "Stick", "javascript", "it");
t.add("The email address is not valid", "The email address is not valid", "javascript", "it");
t.add("The tickets have been released", "The tickets have been released", "javascript", "it");
t.add("This feature is disabled in demo mode", "This feature is disabled in demo mode", "javascript", "it");
t.add("Time's up", "Time's up", "javascript", "it");
t.add("Today", "Today", "javascript", "it");
t.add("Unstick", "Unstick", "javascript", "it");
t.add("Week", "Week", "javascript", "it");
t.add("days", "days", "javascript", "it");
t.add("days.Fri", "days.Fri", "javascript", "it");
t.add("days.Mon", "days.Mon", "javascript", "it");
t.add("days.Sat", "days.Sat", "javascript", "it");
t.add("days.Sun", "days.Sun", "javascript", "it");
t.add("days.Thu", "days.Thu", "javascript", "it");
t.add("days.Tue", "days.Tue", "javascript", "it");
t.add("days.Wed", "days.Wed", "javascript", "it");
t.add("hr", "hr", "javascript", "it");
t.add("min", "min", "javascript", "it");
t.add("sec", "sec", "javascript", "it");
t.add("weeks", "weeks", "javascript", "it");
})(Translator);
