(function (t) {
// ar
t.add("Add", "\u0623\u0636\u0641", "javascript", "ar");
t.add("Add a new event date", "\u0623\u0636\u0641 \u062a\u0627\u0631\u064a\u062e \u062d\u062f\u062b \u062c\u062f\u064a\u062f", "javascript", "ar");
t.add("Add a new ticket", "\u0623\u0636\u0641 \u062a\u0630\u0643\u0631\u0629 \u062c\u062f\u064a\u062f\u0629", "javascript", "ar");
t.add("An error has occured", "\u0644\u0642\u062f \u062d\u062f\u062b \u062e\u0637\u0623", "javascript", "ar");
t.add("April", "\u0623\u0628\u0631\u064a\u0644", "javascript", "ar");
t.add("August", "\u0623\u063a\u0633\u0637\u0633", "javascript", "ar");
t.add("Cancel", "\u0625\u0644\u063a\u0627\u0621", "javascript", "ar");
t.add("Close", "\u063a\u0644\u0642", "javascript", "ar");
t.add("Confirm", "\u0623\u0643\u062f", "javascript", "ar");
t.add("Confirmation required", "\u0627\u0644\u062a\u0623\u0643\u064a\u062f \u0645\u0637\u0644\u0648\u0628", "javascript", "ar");
t.add("Day", "\u064a\u0648\u0645", "javascript", "ar");
t.add("December", "\u062f\u064a\u0633\u0645\u0628\u0631", "javascript", "ar");
t.add("Duplicate this event date", "\u062a\u0643\u0631\u0627\u0631 \u0647\u0630\u0627 \u0627\u0644\u062a\u0627\u0631\u064a\u062e", "javascript", "ar");
t.add("Duplicate this ticket", "\u062a\u0643\u0631\u0627\u0631 \u0647\u0630\u0647 \u0627\u0644\u062a\u0630\u0643\u0631\u0629", "javascript", "ar");
t.add("Email address", "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0627\u0644\u0643\u062a\u0631\u0648\u0646\u0649", "javascript", "ar");
t.add("February", "\u0641\u0628\u0631\u0627\u064a\u0631", "javascript", "ar");
t.add("Insert at least 3 characters", "\u0623\u062f\u062e\u0644 3 \u0623\u062d\u0631\u0641 \u0639\u0644\u0649 \u0627\u0644\u0623\u0642\u0644", "javascript", "ar");
t.add("January", "\u064a\u0646\u0627\u064a\u0631", "javascript", "ar");
t.add("July", "\u064a\u0648\u0644\u064a\u0648", "javascript", "ar");
t.add("June", "\u064a\u0648\u0646\u064a\u0648", "javascript", "ar");
t.add("Load more", "\u062a\u062d\u0645\u064a\u0644 \u0627\u0644\u0645\u0632\u064a\u062f", "javascript", "ar");
t.add("Loading...", "\u062c\u0627\u0631 \u0627\u0644\u062a\u062d\u0645\u064a\u0644...", "javascript", "ar");
t.add("Mail server test email", "\u0627\u062e\u062a\u0628\u0627\u0631 \u062e\u0627\u062f\u0645 \u0627\u0644\u0628\u0631\u064a\u062f", "javascript", "ar");
t.add("Make sure to enter a valid email address", "\u062a\u0623\u0643\u062f \u0645\u0646 \u0625\u062f\u062e\u0627\u0644 \u0639\u0646\u0648\u0627\u0646 \u0628\u0631\u064a\u062f \u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u0635\u0627\u0644\u062d", "javascript", "ar");
t.add("There are multiple hours at this date", "There are multiple hours at this date", "javascript", "ar");
t.add("March", "\u0645\u0627\u0631\u0633", "javascript", "ar");
t.add("May", "\u0645\u0627\u064a\u0648", "javascript", "ar");
t.add("Message", "\u0627\u0644\u0631\u0633\u0627\u0644\u0629", "javascript", "ar");
t.add("Month", "\u0634\u0647\u0631", "javascript", "ar");
t.add("Move down", "\u062a\u0646\u0642\u064a\u0644 \u0644\u0644\u0623\u0633\u0641\u0644", "javascript", "ar");
t.add("Move up", "\u062a\u0646\u0642\u064a\u0644 \u0644\u0644\u0623\u0639\u0644\u0649", "javascript", "ar");
t.add("No results found", "\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u0646\u062a\u0627\u0626\u062c", "javascript", "ar");
t.add("Note", "\u0645\u0644\u062d\u0648\u0638\u0629", "javascript", "ar");
t.add("November", "\u0646\u0648\u0641\u0645\u0628\u0631", "javascript", "ar");
t.add("October", "October", "javascript", "ar");
t.add("Please select the tickets quantity for this order", "\u0627\u0644\u0631\u062c\u0627\u0621 \u062a\u062d\u062f\u064a\u062f \u0643\u0645\u064a\u0629 \u0627\u0644\u062a\u0630\u0627\u0643\u0631 \u0644\u0647\u0630\u0627 \u0627\u0644\u0637\u0644\u0628", "javascript", "ar");
t.add("Please select the tickets quantity you want to buy", "\u0627\u0644\u0631\u062c\u0627\u0621 \u062a\u062d\u062f\u064a\u062f \u0643\u0645\u064a\u0629 \u0627\u0644\u062a\u0630\u0627\u0643\u0631 \u0627\u0644\u062a\u064a \u062a\u0631\u064a\u062f \u0634\u0631\u0627\u0621\u0647\u0627", "javascript", "ar");
t.add("Read more", "\u0642\u0631\u0627\u0621\u0629 \u0627\u0644\u0645\u0632\u064a\u062f", "javascript", "ar");
t.add("Remove", "\u062d\u0630\u0641", "javascript", "ar");
t.add("Remove this event date", "\u062d\u0630\u0641 \u0647\u0630\u0627 \u0627\u0644\u062a\u0627\u0631\u064a\u062e", "javascript", "ar");
t.add("Remove this ticket", "\u062d\u0630\u0641 \u0647\u0630\u0647 \u0627\u0644\u062a\u0630\u0643\u0631\u0629", "javascript", "ar");
t.add("Return to cart", "\u0627\u0644\u0639\u0648\u062f\u0629 \u0625\u0644\u0649 \u0639\u0631\u0628\u0629 \u0627\u0644\u062a\u0633\u0648\u0642", "javascript", "ar");
t.add("Searching ...", "\u0628\u062d\u062b...", "javascript", "ar");
t.add("Select an option", "\u062d\u062f\u062f \u0627\u062e\u062a\u064a\u0627\u0631", "javascript", "ar");
t.add("Send", "\u0625\u0631\u0633\u0627\u0644", "javascript", "ar");
t.add("September", "\u0633\u0628\u062a\u0645\u0628\u0631", "javascript", "ar");
t.add("Stick", "\u0644\u0635\u0642", "javascript", "ar");
t.add("The email address is not valid", "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0628\u0631\u064a\u062f \u0627\u0644\u0625\u0644\u0643\u062a\u0631\u0648\u0646\u064a \u063a\u064a\u0631 \u0635\u0627\u0644\u062d", "javascript", "ar");
t.add("The tickets have been released", "\u062a\u0645 \u0625\u0644\u063a\u0627\u0621 \u0639\u0646 \u0627\u0644\u062a\u0630\u0627\u0643\u0631 \u0627\u0644\u0645\u062d\u062c\u0648\u0632\u0629", "javascript", "ar");
t.add("This feature is disabled in demo mode", "\u062a\u0645 \u062a\u0639\u0637\u064a\u0644 \u0647\u0630\u0647 \u0627\u0644\u0645\u064a\u0632\u0629 \u0641\u064a \u0627\u0644\u0648\u0636\u0639 \u0627\u0644\u062a\u062c\u0631\u064a\u0628\u064a", "javascript", "ar");
t.add("Time's up", "\u0627\u0646\u062a\u0647\u0649 \u0627\u0644\u0648\u0642\u062a", "javascript", "ar");
t.add("Today", "\u0627\u0644\u064a\u0648\u0645", "javascript", "ar");
t.add("Unstick", "\u0641\u0635\u0644", "javascript", "ar");
t.add("Week", "\u0623\u0633\u0628\u0648\u0639", "javascript", "ar");
t.add("days", "\u0623\u064a\u0627\u0645", "javascript", "ar");
t.add("days.Fri", "\u062c\u0645\u0639\u0629", "javascript", "ar");
t.add("days.Mon", "\u0627\u062b\u0646\u064a\u0646", "javascript", "ar");
t.add("days.Sat", "\u0633\u0628\u062a", "javascript", "ar");
t.add("days.Sun", "\u0623\u062d\u062f", "javascript", "ar");
t.add("days.Thu", "\u062e\u0645\u064a\u0633", "javascript", "ar");
t.add("days.Tue", "\u062b\u0644\u0627\u062b\u0627\u0621", "javascript", "ar");
t.add("days.Wed", "\u0623\u0631\u0628\u0639\u0627\u0621", "javascript", "ar");
t.add("hr", "\u0633\u0627\u0639\u0627\u062a", "javascript", "ar");
t.add("javascript string translation", "javascript string translation", "javascript", "ar");
t.add("min", "\u062f\u0642\u064a\u0642\u0629", "javascript", "ar");
t.add("sec", "\u062b\u0627\u0646\u064a\u0629", "javascript", "ar");
t.add("weeks", "\u0623\u0633\u0627\u0628\u064a\u0639", "javascript", "ar");
t.add("Stage", "Stage", "javascript", "en");
t.add("Change stage name", "Change stage name", "javascript", "en");
t.add("Change stage background color", "Change stage background color", "javascript", "en");
t.add("Manage section seats", "Manage section seats", "javascript", "en");
t.add("Change section name", "Change section name", "javascript", "en");
t.add("Change section background color", "Change section background color", "javascript", "en");
t.add("Duplicate section", "Duplicate section", "javascript", "en");
t.add("Delete section", "Delete section", "javascript", "en");
t.add("Add a new row", "Add a new row", "javascript", "en");
t.add("Row", "Row", "javascript", "en");
t.add("Row label", "Row label", "javascript", "en");
t.add("Row prefix", "Row prefix", "javascript", "en");
t.add("Seats numbers direction", "Seats numbers direction", "javascript", "en");
t.add("Row seats color", "Row seats color", "javascript", "en");
t.add("Row seats start number", "Row seats start number", "javascript", "en");
t.add("Row seats end number", "Row seats end number", "javascript", "en");
t.add("Disabled seats", "Disabled seats", "javascript", "en");
t.add("Hidden seats", "Hidden seats", "javascript", "en");
t.add("Left to right", "Left to right", "javascript", "en");
t.add("Right to left", "Right to left", "javascript", "en");
t.add("Section name has to be unique", "Section name has to be unique", "javascript", "en");
t.add("Duplicated", "Duplicated", "javascript", "en");
t.add("Delete section row", "Delete section row", "javascript", "en");
t.add("Row prefix can not be empty", "Row prefix can not be empty", "javascript", "en");
t.add("Row prefix must be unique per section", "Row prefix must be unique per section", "javascript", "en");
t.add("Row seats color can not be empty", "Row seats color can not be empty", "javascript", "en");
t.add("Row seats start number can not be empty", "Row seats start number can not be empty", "javascript", "en");
t.add("Row seats end number can not be empty", "Row seats end number can not be empty", "javascript", "en");
t.add("Row seats end number can not be lesser than Row seats start number", "Row seats end number can not be lesser than Row seats start number", "javascript", "en");
t.add("No matches found", "No matches found", "javascript", "en");
t.add("You have reached the limit for this ticket type", "You have reached the limit for this ticket type", "javascript", "en");
t.add("Please select the tickets quantity for this order", "Please select the tickets quantity for this order", "javascript", "en");
})(Translator);